<template>
  <label class="radio option option--radio">
    <input 
      type="radio" 
      class="option__input hiddenVisually"
      :checked="val==value"
      @change="$emit('input', val)"
    >
    <span class="radio__label flex option__label">
      <span class="flex-shrink-none">
        <span class="block option__radioIcon"></span>
      </span>
      <span class="option__labelText">{{ label }}</span>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: [String,Number],
      default() {
        return ''
      }
    },
    val: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.hiddenVisually {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
}
.option__label {
    cursor: pointer;
    display: block;
    position: relative;
}
.flex {
    display: flex !important;
}
.flex-shrink-none {
    flex-shrink: 0 !important;
}
.option__radioIcon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #008248;
    position: relative;
}
.block {
    display: block !important;
}
.option__input:checked+.option__label .option__radioIcon::before {
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    content: '';
    border-radius: 50%;
    background-color: #008248;
    -webkit-animation: 0.3s option-label-marker-expansion cubic-bezier(0.32, 2.32, 0.61, 0.27) forwards;
    animation: 0.3s option-label-marker-expansion cubic-bezier(0.32, 2.32, 0.61, 0.27) forwards;
}
.option__labelText {
    padding-top: 2px;
    padding-left: 10px;
    min-width: 0;
}
</style>