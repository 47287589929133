<template>
  <div>
  </div>
</template>

<script>

import {mapMutations} from "vuex";

export default {
  created() {
    this.logout();
    this.$router.push({path:'/'});
  },
  methods:{
    ...mapMutations({
      logout: "auth/logout",
    })
  }
}
</script>