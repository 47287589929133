<template>
  <div class="signup">

    <FormForgotPassword></FormForgotPassword>

    <div class="form__comin">{{$t('forgot.note')}}</div>
    <router-link 
      to="/"
      class="signup__register btn btn__secondary btn__secondary_outline-black"
    >
      {{$t('forgot.linkSignIn')}}
    </router-link>
  </div>
</template>

<script>
import FormForgotPassword from '@/components/forgotpassword/Index';

export default {
  components: {
    FormForgotPassword
  }
}
</script>

<style lang="scss">
  .signup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 56px 0;
    // TODO: Исправить по высоте
    min-height: calc(100vh - 100px - 260px);

    .form {
      width: 330px;

      &__footer {
        a {
          margin-left: auto;
        }
      }

      &__comin {
        margin-top: 32px;
      }
    }

    &__register {
      margin-top: 33px;
    }
  }
</style>
