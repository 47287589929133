<template>
  <div 
    class="mobile-menu"
    :class="{ 'mobile-menu_active': isOpen }"
  >
    <div class="container">
      <div class="mobile-menu__links">
        <template v-if="!loggedIn">
          <router-link
              :to="{name: 'Index'}"
              class="mobile-menu__link"
          >
            {{$t('header.login')}}
          </router-link>
          <router-link
              :to="{name: 'Signup'}"
              class="mobile-menu__link"
          >
            {{$t('header.signup')}}
          </router-link>
        </template>
        <template v-else>
          <router-link
              to="/"
              class="mobile-menu__link"
          >
            {{$t('header.menu.main')}}
          </router-link>
        <div class="mobile-menu__link">
          <Accordion
            :data="[
              { 
                title: $t('header.menu.profile'),
                type: 'link',
                links: [
                  { text: 'Личная информация', href: '/profile/personal'},
                  { text: 'Контактная информация', href: '/profile/contact'},
                ] 
              }
            ]"
          />
        </div>
        <div class="mobile-menu__link"
          v-if="card"
        >
          <Accordion
            :data="[
              { 
                title: $t('header.menu.cards'),
                type: 'link',
                links: [
                // TODO: поправить путь
                  { text: 'Информация о карте', href: '/cards'},
                  { text: 'Пополнить карту', href: '/cards/'+card.Number+'/top-up'},
                  { text: 'Перевести средства', href: '/cards/'+card.Number+'/transfer'},
                  { text: 'История операций', href: '/cards/'+card.Number+'/history'},
                ] 
              }
            ]"
          />
        </div>
        <router-link 
          :to="{name: 'About'}" 
          class="mobile-menu__link"
        >
          {{$t('header.about')}}
        </router-link>
        <router-link
            :to="{name: 'Logout'}"
            class="mobile-menu__link"
        >
          {{$t('header.menu.logout')}}
        </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion/Accordion';
import {mapGetters} from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
      locale:"language/locale",
      cards:"cards/cards"
    }),
    card(){

      for (let card of this.cards){
        return card;
      }

      return null;
    }
  },
  methods: {},
  components: {
    Accordion,
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables';

  .mobile-menu {
    position: fixed;
    top: 52px;
    left: 0;
    z-index: 15;
    width: 100%;
    height: calc(100vh - 52px);
    background-color: $color-ceramic;
    padding: 35px 0;
    transform: translateX(100vw);
    transition: 0.5s;

    &_active {
      transform: translateX(0);
    }

    &__link {
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.24px;
      text-transform: uppercase;
      padding: 20px 0;
      color: rgba($color-dark, 0.7);
      border-bottom: 1px solid rgba($color-dark, 0.25);

      &s {
        display: flex;
        flex-direction: column;
      }

      .accordion {
        &__item {
          &-title,
          &-content {
            font-size: 14px;
            line-height: 120%;
            letter-spacing: -0.24px;
          }

          &-trigger {
            align-items: center;
          }

          &-wrapper {
            padding-left: 12px;
          }
        }
      }
    }
  }
</style>
