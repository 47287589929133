<template>
  <div 
    class="accordion__item" 
    :class="{ 'accordion__item_active': visible }"
  >
    <div 
      class="accordion__item-trigger" 
      :class="{ 'accordion__item-trigger_active': visible }"
      @click="setActiveItem"
    >
      <div class="accordion__item-title">{{ accordion_data.title }}</div>
      <div class="accordion__item-icon" :class="{ 'accordion__item-icon_active': visible }">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.4">
            <path d="M5.7625 8.5L11.5 14.0628L17.2375 8.5L19 10.2126L11.5 17.5L4 10.2126L5.7625 8.5Z" fill="black"/>
          </g>
        </svg>
      </div>
    </div>
    <transition 
      name="accordion" 
      @enter="start" 
      @after-enter="end" 
      @before-leave="start" 
      @after-leave="end"
    >
      <div class="accordion__item-wrapper" v-show="visible">
        <div class="accordion__item-content" v-if="!accordion_data.type">{{ accordion_data.content }}</div>
        <div 
          class="accordion__item-content" 
          v-else
          v-for="link in accordion_data.links"
          :key="link.href"
        >
          <router-link :to="link.href">
            {{ link.text }}
          </router-link>
        </div>
        <div 
          v-if="accordion_data.btn"
          class="btn btn__secondary btn__secondary_outline-green accordion__item-btn"
        >
          {{ accordion_data.btn }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  props: {
    accordion_data: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    setActiveItem() {
      this.visible = !this.visible;
    },
    start(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      el.style.height = '';
    },
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables';

  .accordion {
    &__item {
      &:not(:last-child) {
        margin-bottom: 32px;
      }

      &-trigger {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
      }

      &-title {
        font-size: 23px;
        line-height: 110%;
        letter-spacing: -0.24px;
        margin-right: 50px;

        @include breakpoint(1100) {
          font-size: 18px;
          margin-right: 12px;
        }
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition;

        &_active {
          transform: rotate(180deg);
        }
      }

      &-content {
        font-size: 16px;
        line-height: 150%;
        color: rgba($color-dark, 0.6);
        margin-top: 44px;

        @include breakpoint(1100) {
          margin-top: 28px;
        }
      }

      &-btn {
        // margin-top: 32px;
        white-space: nowrap;
        float: left;
      }
    }
  }
</style>
