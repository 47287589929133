<template>
  <component v-bind:is="currentComponent"></component>
</template>

<script>
import PublicOfferRu from '@/views/parts/PublicOfferRu';
import PublicOfferKz from '@/views/parts/PublicOfferKz';

export default {
  data() {
    return {
      currentComponent: 'PublicOfferRu'
    }
  },
  components: {
    PublicOfferRu,
    PublicOfferKz
  },
  watch: {
    '$i18n.locale': function () {
      return this.currentComponent = this.$i18n.locale === 'ru' ? PublicOfferRu : PublicOfferKz;
    }
  },
  mounted() {
    this.currentComponent = this.$i18n.locale === 'ru' ? PublicOfferRu : PublicOfferKz;
  }  
}
</script>