<template>
  <div class="container">
    <div class="login">
      <div class="login__part login__part_form">
        <div class="form__wrapper">
          <Login></Login>
          <div class="form__comin">{{ $t('index.text') }}</div>
          <router-link
              to="/signup"
              class="btn btn__secondary btn__secondary_outline-black"
          >
            {{ $t('index.signup') }}
          </router-link>
        </div>
      </div>
      <div class="login__part_right">
        <Info/>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/components/auth/Index';
import Info from '@/views/parts/Info';

export default {
  components: {
    Login,
    Info
  }
}
</script>

<style lang="scss">

@import '@/assets/scss/_variables';

  .login {
    display: flex;

    @include breakpoint(991) {
      flex-direction: column;
    }

    &__part {
      &-title {
        font-size: 34px;
        line-height: 110%;
        letter-spacing: -0.24px;
        max-width: 550px;

        @include breakpoint(991) {
          font-size: 24px;
        }
      }

      &-subtitle {
        font-size: 18px;
        line-height: 133%;
        color: rgba($color-dark, 0.8);
        margin-top: 12px;
        margin-bottom: 36px;

        @include breakpoint(991) {
          font-size: 16px;
        }
      }

      &-container {
        padding-top: 84px;

        @include breakpoint(991) {
          padding-top: 56px;
        }
      }

      &_form {
        width: 40%;
        padding-top: 45px;
        display: flex;
        justify-content: center;
        background-color: $color-white;

        @include breakpoint(991) {
          width: 100%;
        }
      }

      &_right {
        padding-top: 0;
        width: 60%;
        padding-bottom: 84px;

        @include breakpoint(991) {
          width: 100%;
        }
      }
    }

    .form {
      width: 285px;

      @include breakpoint(991) {
        width: 100%;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        @include breakpoint(991) {
          padding-bottom: 32px;
        }
      }


      &__forgot {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.154px;
        text-decoration: underline;
        color: $color-green-apron;
      }

      &__comin {
        margin: 33px 0;

        @include breakpoint(1100) {
          font-size: 11px;
        }
      }
    }
  }
</style>
