<template>
  <component v-bind:is="currentComponent"></component>
</template>

<script>
import PublicOfferCardsRu from '@/views/parts/PublicOfferCardsRu';
import PublicOfferCardsKz from '@/views/parts/PublicOfferCardsKz';

export default {
  data() {
    return {
      currentComponent: 'PublicOfferCardsRu'
    }
  },
  components: {
    PublicOfferCardsRu,
    PublicOfferCardsKz
  },
  watch: {
    '$i18n.locale': function () {
      return this.currentComponent = this.$i18n.locale === 'ru' ? PublicOfferCardsRu : PublicOfferCardsKz;
    }
  },
  mounted() {
    this.currentComponent = this.$i18n.locale === 'ru' ? PublicOfferCardsRu : PublicOfferCardsKz;
  }  
}
</script>