<template>
  <div class="info">
    <div class="info__description" v-if="description === 'login'">
      <div class="info__description-wrapper">
        <div class="info__description-title">
          «Менің Starbucks марапаттарым» — сатып алып, Жұлдыздар жинаңыз!
        </div>
        <div class="info__description-text">
          Бағдарламаға қатысушы болу үшін «Менің Starbucks марапаттарым» бағдарламасына тіркелу қажет. Starbucks картасын 2000 
          теңгеден жоғары сомаға белсендіргенде, сыйлыққа марапат* аласыз. Марапат аккаунтыңызға бір тәулік ішінде қосылады және 
          «Марапаттар» бөлімінде көрсетіледі.
        </div>
      </div>
    </div>

    <div class="info__programm" v-if="description === 'programm'">
      <div class="info__part-title info__programm-title">
        «Менің Starbucks марапаттарым» — сатып алып, Жұлдыздар жинаңыз!
      </div>
      <div class="info__programm-content">
        <img src="@/assets/img/tabs-level-1.svg" alt="" class="info__programm-img">
        <div class="info__programm-right">
          <StarList 
            :items="[
              'Сатып алу кезінде тіркелген Starbucks картасымен төлеу арқылы Жұлдыздар жинаңыз.',
              'Әр 12 Жұлдызға марапат алыңыз.',
              'Марапаттарды сүйікті Starbucks сусындарыңызға айырбастаңыз*.'
            ]"
            color="#1E3932"
          />
          <div class="profile-separator"></div>
          <div class="info__programm-text">
            Бағдарламаға қатысушы болу үшін «Менің Starbucks марапаттарым» бағдарламасына тіркелу қажет. Starbucks картасын 2000 теңгеден жоғары сомаға белсендіргенде, сыйлыққа марапат* аласыз. Марапат аккаунтыңызға бір тәулік ішінде қосылады және «Марапаттар» бөлімінде көрсетіледі.
          </div>
        </div>
      </div>
    </div>

    <div class="info__stars">
      <div class="info__part-container">
        <div class="info__stars-title info__part-title">Звезды накапливаются быстро!</div>
        <div class="info__stars-subtitle info__part-subtitle">
          Смотрите как накапливаются ваши звезды и получайте больше напитков
        </div>
        <div class="info__stars-steps">
          <div class="info__stars-step">
            <div class="info__stars-step-icon">
              <img src="@/assets/img/star-salary.svg" alt="">
            </div>
            <div class="info__stars-step-text">
              Сатып алу кезінде тіркелген Starbucks картасымен төлеу арқылы Жұлдыздар жинаңыз.
            </div>
          </div>
          <div class="info__stars-step">
            <div class="info__stars-step-icon">
              <img src="@/assets/img/star-get.svg" alt="">
            </div>
            <div class="info__stars-step-text">
              Әр 12 Жұлдызға марапат алыңыз.
            </div>
          </div>
          <div class="info__stars-step">
            <div class="info__stars-step-icon">
              <img src="@/assets/img/star-coffie.svg" alt="">
            </div>
            <div class="info__stars-step-text">
              Марапаттарды сүйікті Starbucks сусындарыңызға айырбастаңыз*.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__levels">
      <div class="info__part-container">
        <div class="info__levels-title info__part-title">
          Бағдарламада Жасыл және Алтын деңгейлер бар
        </div>
        <div class="info__levels-cards">
          <div class="info__levels-card-col">
            <div class="info__levels-card">
              <img src="@/assets/img/level-1.svg" alt="" class="info__levels-card-img">
              <div class="info__levels-card-content">
                <div class="info__levels-card-title">ЖАСЫЛ ДЕҢГЕЙ</div>
                <StarList 
                  :items="[
                    'Әр 12 Жұлдыз үшін сыйлыққа бір сусын беріледі*',
                    'Арнайы ұсыныстар'
                  ]"
                />
              </div>
            </div>
            <div class="info__levels-description">
              <div class="info__levels-description-col-title">
                Жасыл деңгей тіркеле салысымен беріледі.
              </div>
              <div class="info__levels-description-col-text">
                Жасыл деңгейде қалу үшін Сізге 12 айда кемінде бір Жұлдыз алу қажет. 12 ай ішінде бір де бір Жұлдыз жинамасаңыз, 
                Жасыл деңгей Жұлдыздары нөлденеді. Мұндай кезде марапатқа* дейінгі Жұлдыз санағыш өзгеріссіз қалады.
              </div>
            </div>
          </div>
          <div class="info__levels-card-col">
            <div class="info__levels-card">
              <img src="@/assets/img/level-2.svg" alt="" class="info__levels-card-img">
              <div class="info__levels-card-content">
                <div class="info__levels-card-title">АЛТЫН ДЕҢГЕЙ</div>
                <StarList 
                  :items="[
                    'Әр 12 Жұлдыз үшін және туған күн құрметіне сыйлыққа сусын беріледі*',
                    'Алтын деңгейінің эксклюзив ұсыныстары'
                  ]"
                />
              </div>
            </div>
            <div class="info__levels-description">
              <div class="info__levels-description-col-title">
                Алтын деңгейге өту және сол деңгейде қалу үшін Сізге 12 ай ішінде 30 Жұлдыз жинау керек.
              </div>
              <div class="info__levels-description-col-text">
                Егер 12 ай ішінде 30 Жұлдыз жинамасаңыз, Алтын деңгей нөлденеді. Мұндай кезде марапатқа* дейінгі Жұлдыз санағыш өзгеріссіз қалады.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__register">
      <div class="info__part-container">
        <div class="info__register-title info__part-title">Тіркелу</div>
        <div class="info__register-subtitle info__part-subtitle">
          «Менің Starbucks марапаттарым» бағдарламасына 
          <a href="https://www.card.starbucks.com.kz" target="_blank">www.card.starbucks.com.kz</a>
          сайты, не болмаса 
          <a href="https://apps.apple.com/kz/app/starbucks-kazakhstan/id1143164288" target="_blank">iOS</a>
          немесе 
          <a href="https://play.google.com/store/apps/details?id=com.starbucks.kz&hl=en_US&gl=US" target="_blank">Android</a>
          жүйелеріне арналған мобильді қолданбалар арқылы тіркелуге болады.
        </div>
        <div class="info__register-items">
          <div class="info__register-item">
            <div class="info__register-item-counter">1</div>
            <div class="info__register-item-content">
              <div class="info__register-item-title">
                Аккаунтты мобильді қолданбада тіркеу:
              </div>
              <ul class="info__register-item-list">
                <li class="info__register-item-list-item">
                  Starbucks картасының нөмірі мен ПИН-кодын енгізіңіз. ПИН-код картаның артқы жағында орналасқан.
                </li>
                <li class="info__register-item-list-item">
                  Электрондық пошта мекенжайын енгізіп, 6-15 таңбадан тұратын құпиясөз жасаңыз да, жасалған құпиясөзді 
                  растаңыз, содан кейін атыңыз бен тегіңізді, туған күніңізді (күні мен айы), ұялы телефоныңыздың нөмірін 
                  енгізіңіз, сонымен қатар Бағдарламаның барлық шарттарымен танысқаныңызды және келісетініңізді растаңыз да, 
                  «Аккаунт жасау» опциясын таңдаңыз. Маңызды: Сіздің логиніңіз ұялы телефоныңыздың нөмірі болады, оның 
                  дұрыстығын тексеріңіз.
                </li>
              </ul>
            </div>
          </div>
          <div class="info__register-item">
            <div class="info__register-item-counter">2</div>
            <div class="info__register-item-content">
              <div class="info__register-item-title">Аккаунтты www.card.starbucks.com.kz сайты арқылы тіркеу:</div>
              <ul class="info__register-item-list">
                <li class="info__register-item-list-item">
                  Логин ретінде қолданылатын ұялы телефон нөмірін енгізіңіз. Ұялы телефоныңыздың нөмірін 
                  растаңыз және SMS арқылы келетін растау кодын енгізіңіз.
                </li>
                <li class="info__register-item-list-item">
                  Атыңыз бен тегіңізді, электрондық пошта мекенжайыңызды енгізіңіз, құпиясөзді жасап, растаңыз, 
                  туған күніңіз бен айын көрсетіңіз, сондай-ақ Бағдарламаның барлық шарттарымен танысқаныңызды және 
                  келісетініңізді растаңыз да, «Аккаунтты жасау» опциясын таңдаңыз.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__mobile">
      <div class="info__part-container">
        <div class="info__mobile-title info__part-title">Мобильді қолданба</div>
        <div class="info__mobile-subtitle info__part-subtitle">
          iOS немесе Android жүйелеріне арналған «Менің Starbucks марапаттарым» мобильді қолданбасын орнатыңыз.
        </div>
        <div class="info__mobile-content">
          <img src="@/assets/img/mobile.svg" class="info__mobile-content-icon">
          <div class="info__mobile-content-description">
            <div class="info__mobile-content-description-title">
              «Менің Starbucks марапаттарым» мобильді қолданбасы — бұл телефон экранынан Starbucks тапсырысы үшін төлем жасаудың ыңғайлы тәсілі.
            </div>
            <div class="info__mobile-content-description-text">
              Қолданба жақын жердегі кофеханаларды, қолжетімді балансты, транзакциялар тарихын көруге, Starbucks картасын банк картасынан 
              толықтыруға, балансты карталар арасында ауыстыруға, Wallet әмиянына карта қосуға, Starbucks картасының автоматты түрде 
              толтырылуын реттеуге және арнайы ұсыныстар туралы біліп отыруға мүмкіндік береді.
            </div>
            <div class="info__mobile-content-description-btns">
              <a href="https://apps.apple.com/kz/app/starbucks-kazakhstan/id1143164288" target="_blank" class="info__mobile-content-description-btn">
                <img src="@/assets/img/appstore.svg" alt="">
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.starbucks.kz&hl=en_US&gl=US" target="_blank" class="info__mobile-content-description-btn">
                <img src="@/assets/img/googleplay.svg" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__release">
      <div class="info__part-container">
        <div class="info__release-title info__part-title">
          Starbucks картасын қайта шығару
        </div>
        <div class="info__release-text">
          <div>
            Сізде әлі Starbucks картасы болмаса, тікелей қолданбадан виртуалды карта шығартып, оны бекітілген банк картасынан толықтыра аласыз.
          </div>
          <div>
            Ең қызықты ұсыныстар, жаңалықтар мен акциялар — Starbucks қолданбасымен барлығын бірінші болып білесіз!
          </div>
        </div>
      </div>
    </div>

    <div class="info__question">
      <div class="info__question-container">
        <div class="info__question-block">
          <div class="info__question-title info__part-title">Сұрақтар туындады ма?</div>
          <div class="info__question-text">
            Сұрақтарыңыз болса, Қонақтарды қолдау қызметіне 
            <a href="tel:88000802185">8 800 080 21 85</a>
            телефоны арқылы жұмыс күндері 9:00-20:30 аралығында немесе 
            <a href="mailto:csupport@alshaya.kz">csupport@alshaya.kz</a>
            поштасы арқылы хабарласыңыз.
          </div>
        </div>
      </div>
      <div class="info__question-description">
        * Әр 12 (он екі) жаңа Жұлдыз үшін Сіз компанияның кофеханасы ассортиментінен қоспалары бар 
        (қоспалардың максимум саны – 2 бірлік) кез келген көлемдегі арнайы дайындалған бір сусын ала 
        аласыз (сусын көлемі Толл (354 мл.), Гранде (473 мл.) және Венти (591 мл.) мөлшерінен аспауы керек).
      </div>
    </div>
  </div>
</template>

<script>
import StarList from '@/components/Controlls/StarList';

export default {
  components: {
    StarList,
  },
  props: {
    description: {
      type: String,
      default() {
        return 'login'
      }
    }
  }
}
</script>
