<template>
  <div class="signup">

    <FormSignup></FormSignup>

    <div class="form__comin">{{$t('signup.note')}}</div>
    <router-link 
      to="/"
      class="signup__register btn btn__secondary btn__secondary_outline-black"
    >
      {{$t('signup.linkSignIn')}}
    </router-link>
  </div>
</template>

<script>
import FormSignup from '@/components/signup/Index';

export default {
  components: {
    FormSignup
  }
}
</script>

<style lang="scss">
  .signup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 56px 0;
    // TODO: Исправить по высоте
    min-height: calc(100vh - 100px - 260px);

    .form {
      width: 330px;
      margin: auto;

      &__footer {
        a {
          margin-left: auto;
        }
      }

      &__comin {
        margin-top: 32px;
      }
    }

    &__register {
      margin-top: 33px;
    }
  }
</style>
