var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-menu",class:{ 'mobile-menu_active': _vm.isOpen }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mobile-menu__links"},[(!_vm.loggedIn)?[_c('router-link',{staticClass:"mobile-menu__link",attrs:{"to":{name: 'Index'}}},[_vm._v(" "+_vm._s(_vm.$t('header.login'))+" ")]),_c('router-link',{staticClass:"mobile-menu__link",attrs:{"to":{name: 'Signup'}}},[_vm._v(" "+_vm._s(_vm.$t('header.signup'))+" ")])]:[_c('router-link',{staticClass:"mobile-menu__link",attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('header.menu.main'))+" ")]),_c('div',{staticClass:"mobile-menu__link"},[_c('Accordion',{attrs:{"data":[
            { 
              title: _vm.$t('header.menu.profile'),
              type: 'link',
              links: [
                { text: 'Личная информация', href: '/profile/personal'},
                { text: 'Контактная информация', href: '/profile/contact'} ] 
            }
          ]}})],1),(_vm.card)?_c('div',{staticClass:"mobile-menu__link"},[_c('Accordion',{attrs:{"data":[
            { 
              title: _vm.$t('header.menu.cards'),
              type: 'link',
              links: [
              // TODO: поправить путь
                { text: 'Информация о карте', href: '/cards'},
                { text: 'Пополнить карту', href: '/cards/'+_vm.card.Number+'/top-up'},
                { text: 'Перевести средства', href: '/cards/'+_vm.card.Number+'/transfer'},
                { text: 'История операций', href: '/cards/'+_vm.card.Number+'/history'} ] 
            }
          ]}})],1):_vm._e(),_c('router-link',{staticClass:"mobile-menu__link",attrs:{"to":{name: 'About'}}},[_vm._v(" "+_vm._s(_vm.$t('header.about'))+" ")]),_c('router-link',{staticClass:"mobile-menu__link",attrs:{"to":{name: 'Logout'}}},[_vm._v(" "+_vm._s(_vm.$t('header.menu.logout'))+" ")])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }