<template>
  <component v-bind:is="currentComponent"></component>
</template>

<script>
import Login from '@/views/parts/IndexLogin';
import Profile from '@/views/parts/IndexProfile';

import { mapGetters } from "vuex";

export default {
  components: {
    Login,
    Profile
  },
  data() {
    return {
      currentComponent: 'Login'
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
    })
  },
  methods:{
    update(){
      if(this.loggedIn){
        this.currentComponent='Profile';
      }
      else{
        this.currentComponent='Login';
      }
    }
  },
  created() {
    this.update();
  },
  watch:{
    loggedIn(){
      this.update();
    }
  }
}
</script>