import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import Index from '@/views/Index';
import Signup from '@/views/Signup';
import ForgotPassword from '@/views/ForgotPassword';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import PublicOffer from '@/views/PublicOffer';
import PublicOfferCards from '@/views/PublicOfferCards';
import NotFound from '@/views/404';
import About from '@/views/About';
import Logout from '@/views/Logout';
import PaySuccess from '@/views/PaySuccess';
import PayError from '@/views/PayError';

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup,
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/profile',
      name: 'Profile',
      component: () => import('../views/profile/Index'),
      redirect: '/profile/personal',
      props: true,
      children: [
        {
          path: 'personal',
          name: 'ProfilePersonal',
          component: () => import('../views/profile/Personal'),
        },
        {
          path: 'contact',
          name: 'ProfileContact',
          component: () => import('../views/profile/Contact'),
        },
      ]
    },
    {
      path: '/cards',
      name: 'Cards',
      component: () => import('../views/cards/Index'),
      children: [
        {
          path: ':number',
          name: 'CardInfo',
          component: () => import('../views/cards/Info'),
          props: true
        },
        {
          path: ':number/top-up',
          name: 'CardTopUp',
          component: () => import('../views/cards/TopUp'),
          props: true
        },
        {
          path: ':number/transfer',
          name: 'CardTransfer',
          component: () => import('../views/cards/Transfer'),
          props: true
        },
        {
          path: ':number/history',
          name: 'CardHistory',
          component: () => import('../views/cards/History'),
          props: true
        },
      ]
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/public-offer',
      name: 'PublicOffer',
      component: PublicOffer
    },
    {
      path: '/public-offer-cards',
      name: 'PublicOfferCards',
      component: PublicOfferCards
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/pay-success',
      name: 'PaySuccess',
      component: PaySuccess
    },
    {
      path: '/pay-error',
      name: 'PayError',
      component: PayError
    },
  ],
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

export default router;