import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ru from './i18n/ru.json';
import kz from './i18n/kz.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: {
    ru,
    kz
  },
});
