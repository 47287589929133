import ApiService from "@/services/api.service";

export const contact = {
    namespaced: true,
    state: {
        contact:{}
    },
    getters: {
        contact: state => {
            return state.contact
        },
    },
    actions: {
        getContact({ commit }){

            return ApiService.contact().then((resp)=>{
                commit('setContact',resp.data);
            });
        }
    },
    mutations: {
        setContact(state,contact) {
            state.contact = contact;
        }
    }
};