<template>
  <component v-bind:is="currentComponent"></component>
</template>

<script>
import PrivacyPolicyRu from '@/views/parts/PrivacyPolicyRu';
import PrivacyPolicyKz from '@/views/parts/PrivacyPolicyKz';

export default {
  data() {
    return {
      currentComponent: 'PrivacyPolicyRu'
    }
  },
  components: {
    PrivacyPolicyRu,
    PrivacyPolicyKz
  },
  watch: {
    '$i18n.locale': function () {
      return this.currentComponent = this.$i18n.locale === 'ru' ? PrivacyPolicyRu : PrivacyPolicyKz;
    }
  },
  mounted() {
    this.currentComponent = this.$i18n.locale === 'ru' ? PrivacyPolicyRu : PrivacyPolicyKz;
  }  
}
</script>