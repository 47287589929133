<template>
  <component v-bind:is="currentComponent" :description="description"></component>
</template>

<script>
import InfoRu from './InfoRu';
import InfoKz from './InfoKz'

export default {
  data() {
    return {
      currentComponent: 'InfoRu'
    }
  },
  components: {
    InfoRu,
    InfoKz
  },
  props: {
    description: {
      type: String,
      default() {
        return 'login'
      }
    }
  },
  watch: {
    '$i18n.locale': function () {
      return this.currentComponent = this.$i18n.locale === 'ru' ? InfoRu : InfoKz;
    }
  },
  mounted() {
    this.currentComponent = this.$i18n.locale === 'ru' ? InfoRu : InfoKz;
  }  
}
</script>