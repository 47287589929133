<template>
  <ul class="star-list">
    <li 
      class="star-list__item"
      v-for="item in items"
      :key="item"
      :style="{color: color || 'rgba(0, 0, 0, 0.6)'}"
    >
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  props : {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    color: {
      type: String,
      default() {
        return ''
      }
    },
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables';

  .star-list {
    padding-left: 20px;

    &__item {
      font-size: 16px;
      line-height: 150%;
      list-style-image: url('../../assets/img/star.svg');
      color: rgba($color-dark, 0.6);
      padding-left: 10px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      @include breakpoint(1200) {
        font-size: 14px;
      }
    }
  }
</style>