<template>
  <form class="form" @submit.prevent="submit">
    <div class="form__title">{{$t("components.forgot.title")}}</div>
    <p>{{$t('components.forgot.confirmText')}}</p>
    <p style="color: red;">{{errorText}}</p>
    <Input
        v-model="model.code"
        :label="$t('components.forgot.labelCode')"
        :state="!validation.hasError('model.code')"
        :error-text="validation.firstError('model.code')"
    ></Input>

    <div class="form__input-group" :class="{'form__input-group_error':validation.hasError('model.verifyToken')}">
      <div class="form__input-group-wrapper">
        <vue-recaptcha
            sitekey="6LfmZs4aAAAAAPbk4jnFuWahU3QBPTaTByO7_JTs"
            :loadRecaptchaScript="true"
            @verify="verifyEvent"
            @expired="expiredEvent"
            ref="recaptcha"
        ></vue-recaptcha>
      </div>
      <div class="note form__input-error">
        {{validation.firstError('model.verifyToken')}}
      </div>
    </div>

    <div class="form__footer">
      <button type="submit" class="btn btn__primary btn__primary_fill">
        {{$t('components.forgot.btnConfirm')}}
      </button>
    </div>
  </form>
</template>

<script>

import SimpleVueValidator from 'simple-vue-validator';
const Validator = SimpleVueValidator.Validator;
import VueRecaptcha from 'vue-recaptcha';

import Input from "@/components/Controlls/Input";
import ApiService from "@/services/api.service";

export default {
  components: {
    VueRecaptcha,
    Input
  },
  props: ['data'],
  data () {
    return {
      model: {
        code: '',
        verifyToken:''
      },
      submitted:false,
      errorText:''
    }
  },
  methods:{
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
            if (success) {

              this.errorText = '';

              ApiService.forgotpasswordConfirmCode({
                'token':this.data.token,
                'code':this.model.code,
                'verifyToken': this.model.verifyToken
              }).then((resp)=>{

                let data = resp.data;

                this.$emit('success', {
                  'token':data.token,
                  'nextComponent':'FormPersonalData'
                });
              }).catch((err)=>{
                let data = err.data;
                this.errorText = data.errorText;
                this.$refs.recaptcha.reset();
                this.model.verifyToken='';
              });
            }
          }
      )
    },
    verifyEvent(token){
      this.model.verifyToken = token;
    },
    expiredEvent(){
      this.model.verifyToken = '';
    }
  },
  validators: {
    "model.code": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    },
    "model.verifyToken": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    }
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>

<style lang="scss">
</style>
