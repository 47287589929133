<template>
  <component v-bind:is="currentComponent" v-on:success="stepSuccess" :data="data"></component>
</template>

<script>
import FormSendCode from '@/components/signup/SendCode';
import FormConfirmCode from '@/components/signup/ConfirmCode';
import FormPersonalData from '@/components/signup/PersonalData';

import { mapGetters } from "vuex";

export default {
  components: {
    FormSendCode,
    FormConfirmCode,
    FormPersonalData
  },
  data() {
    return {
      data:{},
      currentComponent:'FormSendCode'
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
    })
  },
  methods:{
    stepSuccess(data){
      this.data = Object.assign(this.data, data);
      this.currentComponent = data.nextComponent;
    }
  },
  created() {
    if(this.loggedIn)
      this.$router.push({path:'/'});
  }
}
</script>

<style lang="scss">
</style>