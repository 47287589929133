<template>
  <label class="checkbox " :class="{'checkbox_error':!state}">
    <input type="checkbox" class="checkbox__input" v-model="localValue">
    <span class="checkbox__label">
      <span class="checkbox__icon">
        <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false" style="width: 24px; height: 24px; overflow: visible; fill: currentcolor;">
          <path d="M4.29 12.104c-.277-.308-.75-.332-1.06-.054-.306.278-.33.752-.053 1.06l4.485 4.963c.29.322.795.33 1.096.017L20.414 6.003c.288-.298.28-.773-.02-1.06-.297-.288-.772-.28-1.06.02L8.237 16.47 4.29 12.105z"></path>
        </svg>
      </span>
      <span class="checkbox__text">{{ label }} {{requred?'*':''}}</span>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean]
    },
    state: {
      type: [Boolean],
      default(){
        return true;
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    requred:{
      type: Boolean,
      default() {
        return false
      }
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
.checkbox {
  &_error {
    .checkbox {
      &__icon {
        border-color: red;
      }

      &__input +.checkbox__label .checkbox__icon {
        animation: 0.3s animate-checkbox cubic-bezier(0.32, 2.32, 0.61, 0.27) forwards;
      }
    }
  }

  &__input {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;

    &:checked {
      &+.checkbox__label .checkbox__icon {
        background-color: #00754A;
        border-color: #00754A;
        color: rgba(255, 255, 255, 1);
        animation: 0.3s animate-checkbox cubic-bezier(0.32, 2.32, 0.61, 0.27) forwards;
      }
    }
  }

  &__text {
    padding-top: 2px;
    padding-left: 10px;
    min-width: 0;
  }

  &__label {
    display: flex !important;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    position: relative;
  }

  &__icon {
    border-radius: 25%;
    display: flex;
    border: 1.5px solid #00754A;
    color: transparent;
  }
}

@keyframes animate-checkbox {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
</style>