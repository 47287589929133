<template>
  <div class="form__input-group" :class="{'form__input-group_error':!state,'form__input-group_focus':labelActive}">
    <div class="form__input-group-wrapper">
      <template v-if="mask">
        <input
            :type="type"
            v-mask="mask"
            class="form__input"
            v-model="localValue"
            @focus="focusInput"
            @focusout="unfocusInput"
            autocomplete="off"
            ref="cInput"
        />
      </template>
      <template v-else>
        <input
            :type="type"
            class="form__input"
            v-model="localValue"
            @focus="focusInput"
            @focusout="unfocusInput"
            autocomplete="off"
            ref="cInput"
        />
      </template>
      <label class="form__label" @click="$refs.cInput.focus()">{{label}} {{requred?'*':''}}</label>
    </div>
    <div class="note form__input-error">
      {{errorText}}
    </div>
    <div class="note" v-if="note">
     {{note}}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// import MaskedInput from "vue-masked-input";

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

export default {
  components: {
  },
  props: {
    value: {
      type: [String,Number]
    },
    type: {
      type: String,
      default() {
        return 'text'
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    note: {
      type: String,
      default() {
        return ''
      }
    },
    errorText:{
      type: String,
      default() {
        return ''
      }
    },
    requred:{
      type: Boolean,
      default() {
        return false
      }
    },
    state:{
      type: Boolean,
      default() {
        return true
      }
    },
    placeholder:{
      type: String,
      default() {
        return ''
      }
    },
    mask:{
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      labelActive: false
    }
  },
  created() {
    if(this.value != null && this.value.toString().length > 0){
      this.labelActive = true;
    }
  },
  methods:{
    focusInput() {
      this.labelActive = true;
    },
    unfocusInput() {
      this.localValue ? this.labelActive = true : this.labelActive = false;
    }
  }
}
</script>

<style lang="scss">
</style>