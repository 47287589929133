<template>
  <form class="form" @submit.prevent="submit">
    <div class="form__title">{{ $t('components.signup.title') }}</div>
    <p style="color: red;">{{errorText}}</p>
    <Input
        v-model="model.phone"
        :label="$t('components.signup.labelPhone')"
        :mask="'+7 (###) ###-##-##'"
        :state="!validation.hasError('model.phone')"
        :error-text="validation.firstError('model.phone')"
        :requred="true"
    ></Input>

    <div class="form__input-group" :class="{'form__input-group_error':validation.hasError('model.verifyToken')}">
      <div class="form__input-group-wrapper">
        <vue-recaptcha
            sitekey="6LfmZs4aAAAAAPbk4jnFuWahU3QBPTaTByO7_JTs"
            :loadRecaptchaScript="true"
            @verify="verifyEvent"
            @expired="expiredEvent"
            ref="recaptcha"
        ></vue-recaptcha>
      </div>
      <div class="note form__input-error">
        {{validation.firstError('model.verifyToken')}}
      </div>
    </div>

    <div class="form__footer">
      <Button visual-style="fill" :loading="isLoading">{{$t('components.signup.btnGetCode')}}</Button>
    </div>
  </form>
</template>

<script>

import SimpleVueValidator from 'simple-vue-validator';
const Validator = SimpleVueValidator.Validator;
import VueRecaptcha from 'vue-recaptcha';

import ApiService from "@/services/api.service";

export default {
  components: {
    VueRecaptcha
  },
  data () {
    return {
      model: {
        phone: '',
        verifyToken:''
      },
      submitted:false,
      errorText:'',
      isLoading:false
    }
  },
  methods:{
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
          if (success) {

            this.errorText = '';
            this.isLoading=true;

            ApiService.signupSendCode(this.model).then((resp)=>{

              let data = resp.data;

              this.$emit('success', {
                'phone': this.model.phone,
                'token': data.token,
                'nextComponent':'FormConfirmCode'
              });
              window.dataLayer.push({'event':'Registration_step_1', 'eventCategory':'Registration_step_1', 'eventAction':'send'});

            }).catch((err)=>{
              let data = err.data;
              this.errorText = data.errorText;
              this.$refs.recaptcha.reset();
              this.model.verifyToken = '';
              this.isLoading=false;
            });
          }
        }
      )
    },
    verifyEvent(token){
      this.model.verifyToken = token;
    },
    expiredEvent(){
      this.model.verifyToken = '';
    }
  },
  validators: {
    "model.phone": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).regex(/\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/, this.$t('errors.phone'));
    },
    "model.verifyToken": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    }
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>

<style lang="scss">
</style>
