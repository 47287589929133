<template>
  <div class="button">
    <div 
      class="btn__wrapper" 
      :class="{ 'btn__wrapper_loading': this.loading }"
      ref="btnWrapper"
    >
      <button
        :type="type"
        class="btn"
        :class="classBtn"
        @click="click"
      >
        <span v-if="!loading">
          <slot></slot>
        </span>
        <span v-else></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name:'Button',
  data() {
    return {
    }
  },
  props: {
    type:{
      type:String,
      default(){
        return 'submit'
      }
    },
    visualType:{
      type:String,
      default(){
        return 'primary'
      }
    },
    visualStyle:{
      type:String,
      default(){
        return 'outline'
      }
    },
    color:{
      type:String,
      default(){
        return 'green'
      }
    },
    loading:{
      type:Boolean,
      default(){
        return false
      }
    },
  },
  computed:{
    classBtn(){

      let objClass = {};

      objClass[`btn__${this.visualType }`]=true;
      objClass[`btn__${this.visualType }_${this.visualStyle}`]=true;
      objClass[`btn__${this.visualType }_${this.visualStyle}-${this.color}`]=true;
      objClass[`btn_loading`]=this.loading;

      return objClass;
    }
  },
  methods: {
    click() {
      this.$refs.btnWrapper.style.width = `${this.$refs.btnWrapper.getBoundingClientRect().width}px`;
      this.$emit('click');
    }
  },
  mounted() {

  },
  updated() {
  }
}
</script>