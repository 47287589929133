import ApiService from "@/services/api.service";

export const cards = {
    namespaced: true,
    state: {
        cards:[]
    },
    getters: {
        cards: state => {
            return state.cards
        },
    },
    actions: {
        getCards({ commit }){
            return ApiService.cards().then((resp)=>{
                commit('setCards',resp.data);
            });
        }
    },
    mutations: {
        setCards(state,cards) {
            state.cards = cards;
        }
    }
};