<template>
  <aside class="profile__aside" v-if="activeCard">
    <div class="profile__aside-item profile__aside-top">
      <img src="@/assets/img/cards.svg" alt="" class="profile__aside-item-img">
      <div class="profile__aside-item-content">
        <router-link 
          :to="{name:'Cards'}"
          tag="div"
          class="profile__aside-item-title"
        >
          {{$t('components.profile.aside.cards')}}
        </router-link>
        <div class="profile__aside-user-points">
          <div class="profile__aside-user-points-value">
            <div class="profile__aside-user-points-count">{{allActiveBalance}}</div>
            <div class="profile__aside-user-points-icon">
              <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.608984 0.719999H11.389V3.24H0.608984V0.719999ZM7.50898 15H4.48898V7.16H0.608984V4.64H11.389V7.16H7.50898V15Z" fill="black"/>
              </svg>
            </div>
          </div>
          <div class="profile__aside-user-points-date">
            {{$t('components.profile.aside.textStateDate')}} {{ contact.dateInfo }}
          </div>
        </div>
        <router-link
            :to="{name:'CardTopUp',params:{number:activeCard.Number}}"
            class="btn btn__primary btn__primary_fill"
            tag="button"
        >
          {{$t('components.profile.aside.linkTopUpAccount')}}
        </router-link>
        <div class="profile__aside-item-actions">
          <router-link
              :to="{name:'CardTransfer',params:{number:activeCard.Number}}"
              class="profile__aside-item-action"
          >
            {{$t('components.profile.aside.linkTransfer')}}
          </router-link>
          <router-link
              :to="{name:'CardInfo',params:{number:activeCard.Number}}"
              class="profile__aside-item-action"
          >
            {{$t('components.profile.aside.linkAddCard')}}
          </router-link>
        </div>
      </div>
    </div>
    <div class="profile__aside-item profile__aside-bottom">
      <router-link 
        :to="{name:'Profile'}" 
        tag="div" 
        class="profile__aside-item-title"
      >
        {{$t('components.profile.aside.linkMyProfile')}}
      </router-link>
      <div class="profile__aside-user-name">{{ contact.FullName }}</div>
      <div class="profile__aside-user-email">{{ contact.EmailAddress }}</div>
    </div>
  </aside> 
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data(){
    return {}
  },
  computed: {
    ...mapGetters({
      contact: "contact/contact",
      cards: "cards/cards"
    }),
    allActiveBalance(){

      let allMoneyBalance = 0;

      for(let card of this.cards){
        allMoneyBalance+=parseFloat(card.MoneyBalance);
      }

      return allMoneyBalance;
    },
    activeCard(){
      if(this.cards[0]){
        return this.cards[0];
      }

      return null;
    }
  },
}
</script>
