<template>
  <div class="container">
    <div class="profile"  v-if="!loading">
      <div class="profile__wrapper">
        <div class="profile__content">
          <Aside v-if="!asideRight" style="margin-top: 0"/>
          <Info description="programm"/>
        </div>
        <Aside v-if="asideRight"/>
      </div>
    </div>
  </div>
</template>

<script>
import Aside from '@/components/profile/Aside';
import Info from '@/views/parts/Info';

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      asideRight: true, 
    }
  },
  components: {
    Aside,
    Info
  },
  mounted() {
    window.addEventListener('resize', this.checkAside);
    this.checkAside();

    this.setStatusLoading(true);

    Promise.all([
      this.getContact(),
      this.getCards()
    ]).then(() => {
      this.setStatusLoading(false);
    });
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      contact: "contact/contact"
    })
  },
  methods:{
    ...mapMutations({
      setStatusLoading: "setStatusLoading",
    }),
    ...mapActions({
      getContact:'contact/getContact',
      getCards:'cards/getCards'
    }),
    checkAside() {
      window.innerWidth > 750 ? this.asideRight = true : this.asideRight = false;
    }
  }
}
</script>