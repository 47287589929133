<template>
  <form class="form" @submit.prevent="submit" autocomplete="off">
    <div class="form__title">{{$t("components.forgot.title")}}</div>
    <p style="color: red;">{{errorText}}</p>
    <Input
        v-model="model.password"
        :label="$t('components.forgot.labelPassword')"
        type="password"
        :state="!validation.hasError('model.password')"
        :error-text="validation.firstError('model.password')"
        :requred="true"
        :note="$t('components.forgot.notePassword')"
    ></Input>

    <Input
        v-model="model.repPassword"
        :label="$t('components.forgot.labelRepPassword')"
        type="password"
        :state="!validation.hasError('model.repPassword')"
        :error-text="validation.firstError('model.repPassword')"
        :requred="true"
    ></Input>

    <div class="form__input-group" :class="{'form__input-group_error':validation.hasError('model.verifyToken')}">
      <div class="form__input-group-wrapper">
        <vue-recaptcha
            sitekey="6LfmZs4aAAAAAPbk4jnFuWahU3QBPTaTByO7_JTs"
            :loadRecaptchaScript="true"
            @verify="verifyEvent"
            @expired="expiredEvent"
            ref="recaptcha"
        ></vue-recaptcha>
      </div>
      <div class="note form__input-error">
        {{validation.firstError('model.verifyToken')}}
      </div>
    </div>

    <button type="submit" class="signup__create btn btn__primary btn__primary_fill">{{$t('components.forgot.btnEdit')}}</button>
  </form>
</template>

<script>
import SimpleVueValidator from 'simple-vue-validator';
const Validator = SimpleVueValidator.Validator;
import VueRecaptcha from 'vue-recaptcha';

import Input from "@/components/Controlls/Input";
import ApiService from "@/services/api.service";

export default {
  components: {
    VueRecaptcha,
    Input
  },
  props: ['data'],
  data () {
    return {
      model: {
        password:'',
        repPassword:'',
        verifyToken:'',
      },
      submitted:false,
      errorText:''
    }
  },
  methods:{
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
            if (success) {

              this.errorText = '';

              ApiService.forgotpassword({
                'token':this.data.token,
                'password':this.model.password,
                'verifyToken': this.model.verifyToken
              }).then(()=>{

                // let data = resp.data;

                this.$router.push({path:'/'});
              }).catch((err)=>{
                let data = err.data;
                this.errorText = data.errorText;
                this.$refs.recaptcha.reset();
                this.model.verifyToken='';
              });
            }
          }
      )
    },
    verifyEvent(token){
      this.model.verifyToken = token;
    },
    expiredEvent(){
      this.model.verifyToken = '';
    }
  },
  validators: {
    "model.password": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).regex('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}',this.$t('errors.password'));
    },
    'model.repPassword, model.password': function (repeat, password) {
      if (this.submitted || this.validation.isTouched('repeat')) {
        return Validator.value(repeat).required(this.$t('errors.required')).match(password,this.$t('errors.repPassword'));
      }
    },
    "model.verifyToken": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    },
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>

<style lang="scss">
</style>
