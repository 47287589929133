<template>
  <div id="app">
    <Header/>
      <Loader v-if="loading"></Loader>
      <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layouts/Header';
import Footer from '@/components/Layouts/Footer';

import { mapGetters } from "vuex";

import Loader from '@/components/Loader';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loader
  },
  computed: {
    ...mapGetters({
      loading: "loading"
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/style';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>