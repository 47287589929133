<template>
  <div class="info">
    <div class="info__description" v-if="description === 'login'">
      <div class="info__description-wrapper">
        <div class="info__description-title">
          «Мои Награды Старбакс» — совершайте покупки, собирайте звезды и{{
            "\xa0"
          }}получайте подарки!
        </div>
        <div class="info__description-text">
          Для того, чтобы стать участником Программы, необходимо зарегистрироваться в «Мои Награды Старбакс». При
          активации карты Starbucks Вы получите скидку 20% на первый заказ. Награда будет начислена на Ваш аккаунт в
          течение суток и отобразится в личном кабинете
        </div>
      </div>
    </div>

    <div class="info__programm" v-if="description === 'programm'">
      <div class="info__part-title info__programm-title">
        «Мои Награды Старбакс» — совершайте покупки, собирайте звезды!
      </div>
      <div class="info__programm-content">
        <img
            src="@/assets/img/tabs-level-1.svg"
            alt=""
            class="info__programm-img"
        />
        <div class="info__programm-right">
          <StarList
              :items="[
              'Зарабатывайте звезды, оплачивая свои покупки зарегистрированной Картой Starbucks.',
              'Получайте награду за каждые 12 звезд.',
              'Обменивайте награды на любимые напитки Starbucks*.',
            ]"
              color="#1E3932"
          />
          <div class="profile-separator"></div>
          <div class="info__programm-text">
            Для того, чтобы стать участником Программы, необходимо
            зарегистрироваться в «Мои Награды Старбакс». Вы получите награду* в
            подарок при активации карты Starbucks на сумму 2000 тг. Награда
            будет начислена на Ваш аккаунт в течение суток и отобразится в
            разделе «Награды».
          </div>
        </div>
      </div>
    </div>

    <div class="info__stars">
      <div class="info__part-container">
        <div class="info__stars-title info__part-title">
          Звезды накапливаются быстро!
        </div>
        <div class="info__stars-subtitle info__part-subtitle">
          Смотрите как накапливаются ваши звезды и получайте больше напитков
        </div>
        <div class="info__stars-steps">
          <div class="info__stars-step">
            <div class="info__stars-step-icon">
              <img src="@/assets/img/star-salary.svg" alt=""/>
            </div>
            <div class="info__stars-step-text">
              Зарабатывайте звезды, оплачивая свои покупки зарегистрированной
              Картой Starbucks.
            </div>
          </div>
          <div class="info__stars-step">
            <div class="info__stars-step-icon">
              <img src="@/assets/img/star-get.svg" alt=""/>
            </div>
            <div class="info__stars-step-text">
              Получайте награду за каждые 12 звезд.
            </div>
          </div>
          <div class="info__stars-step">
            <div class="info__stars-step-icon">
              <img src="@/assets/img/star-coffie.svg" alt=""/>
            </div>
            <div class="info__stars-step-text">
              Обменивайте награды на любимые напитки Starbucks*.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__levels">
      <div class="info__part-container">
        <div class="info__levels-title info__part-title">
          В Программе доступны Зеленый и Золотой уровни
        </div>
        <div class="info__levels-cards">
          <div class="info__levels-card-col">
            <div class="info__levels-card">
              <img
                  src="@/assets/img/level-1.svg"
                  alt=""
                  class="info__levels-card-img"
              />
              <div class="info__levels-card-content">
                <div class="info__levels-card-title">Зеленый уровень</div>
                <StarList
                    :items="[
                    'Напиток в подарок за каждые 12 звезд*',
                    'Специальные предложения',
                  ]"
                />
              </div>
            </div>
            <div class="info__levels-description">
              <div class="info__levels-description-col-title">
                Зеленый уровень доступен сразу после регистрации.
              </div>
              <div class="info__levels-description-col-text">
                Чтобы остаться на Зеленом уровне, Вам необходимо получить хотя
                бы одну звезду в течение 12 месяцев. Если Вы не собрали ни одной
                звезды в течение 12 месяцев, звезды Зеленого уровня обнулятся.
                При этом счетчик звезд до награды* останется неизменным.
              </div>
            </div>
          </div>
          <div class="info__levels-card-col">
            <div class="info__levels-card">
              <img
                  src="@/assets/img/level-2.svg"
                  alt=""
                  class="info__levels-card-img"
              />
              <div class="info__levels-card-content">
                <div class="info__levels-card-title">Золотой уровень</div>
                <StarList
                    :items="[
                    'Напиток в подарок за каждые 12 звезд и в честь дня рождения*',
                    'Эксклюзивные предложения',
                  ]"
                />
              </div>
            </div>
            <div class="info__levels-description">
              <div class="info__levels-description-col-title">
                Чтобы перейти и оставаться на Золотом уровне, Вам необходимо
                собирать по 30 звезд в течение 12 месяцев.
              </div>
              <div class="info__levels-description-col-text">
                Если в течение 12 месяцев Вы не собрали 30 звезд, то Золотой
                уровень обнулится. При этом счетчик звезд до награды* останется
                неизменным.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__register">
      <div class="info__part-container">
        <div class="info__register-title info__part-title">Регистрация</div>
        <div class="info__register-subtitle info__part-subtitle">
          Зарегистрироваться в «Мои Награды Старбакс» можно через сайт
          <a href="https://www.card.starbucks.com.kz" target="_blank"
          >www.card.starbucks.com.kz</a
          >
          или мобильные приложения для
          <a
              href="https://apps.apple.com/kz/app/starbucks-kazakhstan/id1143164288"
              target="_blank"
          >IOS</a
          >
          или
          <a
              href="https://play.google.com/store/apps/details?id=com.starbucks.kz&hl=en_US&gl=US"
              target="_blank"
          >Android</a
          >.
        </div>
        <div class="info__register-items">
          <div class="info__register-item">
            <div class="info__register-item-counter">1</div>
            <div class="info__register-item-content">
              <div class="info__register-item-title">
                Регистрация аккаунта в мобильном приложении
                www.card.starbucks.com.kz:
              </div>
              <ul class="info__register-item-list">
                <li class="info__register-item-list-item">
                  Введите номер и пин-код карты Starbucks. Пин-код находится на
                  оборотной стороне карты.
                </li>
                <li class="info__register-item-list-item">
                  Введите электронный адрес, создайте пароль от 6 до 15
                  символов, подтвердите созданный пароль, введите имя и фамилию,
                  дату рождения (день и месяц), мобильный номер, а также
                  подтвердите ознакомление и согласие со всеми условиями
                  Программы, выберите опцию «Создать аккаунт». Важно: вашим
                  логином будет номер мобильного телефона, проверьте его
                  корректность.
                </li>
              </ul>
            </div>
          </div>
          <div class="info__register-item">
            <div class="info__register-item-counter">2</div>
            <div class="info__register-item-content">
              <div class="info__register-item-title">
                Регистрация аккаунта через сайт www.card.starbucks.com.kz:
              </div>
              <ul class="info__register-item-list">
                <li class="info__register-item-list-item">
                  Введите номер мобильного телефона, который будет
                  использоваться в качестве логина. Подтвердите мобильный номер
                  и введите подтверждающий код, который Вы получите по SMS.
                </li>
                <li class="info__register-item-list-item">
                  Введите имя и фамилию, адрес электронной почты, создайте и
                  подтвердите пароль, укажите день и месяц рождения, а также
                  подтвердите ознакомление и согласие со всеми условиями
                  Программы, выберите опцию «Создать аккаунт».
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__mobile">
      <div class="info__part-container">
        <div class="info__mobile-title info__part-title">
          Мобильное приложение
        </div>
        <div class="info__mobile-subtitle info__part-subtitle">
          Установите мобильное приложение «Мои Награды Старбакс» для
          <a
              href="https://apps.apple.com/kz/app/starbucks-kazakhstan/id1143164288"
              target="_blank"
          >IOS</a
          >
          или
          <a
              href="https://play.google.com/store/apps/details?id=com.starbucks.kz&hl=en_US&gl=US"
              target="_blank"
          >Android</a
          >.
        </div>
        <div class="info__mobile-content">
          <img
              src="@/assets/img/mobile.svg"
              class="info__mobile-content-icon"
          />
          <div class="info__mobile-content-description">
            <div class="info__mobile-content-description-title">
              Мобильное приложение «Мои Награды Старбакс» — это удобный способ
              оплаты заказа Starbucks с экрана телефона.
            </div>
            <div class="info__mobile-content-description-text">
              Приложение позволяет просматривать ближайшие кофейни, доступный
              баланс, историю транзакций, пополнять карту Starbucks с банковской
              карты, перемещать баланс между своими картами, добавлять карту в
              Wallet, настраивать автопополнение карты Starbucks и узнавать о
              специальных предложениях.
            </div>
            <div class="info__mobile-content-description-btns">
              <a
                  href="https://apps.apple.com/kz/app/starbucks-kazakhstan/id1143164288"
                  target="_blank"
                  class="info__mobile-content-description-btn"
              >
                <img src="@/assets/img/appstore.svg" alt=""/>
              </a>
              <a
                  href="https://play.google.com/store/apps/details?id=com.starbucks.kz&hl=en_US&gl=US"
                  target="_blank"
                  class="info__mobile-content-description-btn"
              >
                <img src="@/assets/img/googleplay.svg" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__release">
      <div class="info__part-container">
        <div class="info__release-title info__part-title">
          Перевыпуск карты Старбакс
        </div>
        <div class="info__release-text">
          <div>
            Если у Вас еще нет карты Starbucks, Вы можете выпустить виртуальную
            карту прямо из приложения и пополнить ее с прикрепленной банковской
            карты.
          </div>
          <div>
            Самые интересные предложения, новости и акции — с приложением
            Starbucks Вы узнаете обо всем первыми!
          </div>
        </div>
      </div>
    </div>

    <div class="info__question">
      <div class="info__question-container">
        <div class="info__question-block">
          <div class="info__question-title info__part-title">
            Возникли вопросы?
          </div>
          <div class="info__question-text">
            Если у Вас возникли вопросы, обращайтесь в Службу Поддержки Гостей
            по телефону
            <a href="tel:+77273399860">+7 (727) 339 98 60</a> или
            <a href="tel:+77273399860">8 (800) 080 21 85</a>
            ежедневно с 10:00 до 22:00 часов или на почту
            <a href="mailto:CSupport@Alshaya.kz">CSupport@Alshaya.kz</a>.
          </div>
        </div>
      </div>
      <div class="info__question-description">
        * За каждые 12 (двенадцать) новых Звезд Вы можете получить один
        специально приготовленный напиток любого объема с добавками
        (максимальное количество добавок - 2 ед.) из ассортимента кофейни
        Компании (объем напитка должен не превышать Толл (354 мл.), Гранде (473
        мл.) и Венти (591 мл.)).
      </div>
    </div>
  </div>
</template>

<script>
import StarList from "@/components/Controlls/StarList";

export default {
  components: {
    StarList,
  },
  props: {
    description: {
      type: String,
      default() {
        return "login";
      },
    },
  },
};
</script>
