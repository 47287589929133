// import AuthService from '../services/auth.service';

let user = JSON.parse(localStorage.getItem('user'));

if(user){
    let d = new Date();
    let expTm=user.expires*1000;
    let cutTm = d.getTime();

    if(cutTm>=expTm){
        user=null;
    }
}

const initialState = user ? {
        loggedIn: true,
        user
    }:
    {
        loggedIn: false,
        user: null
    };

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        loggedIn: state => {
            return state.loggedIn
        },
    },
    actions: {
        // login({ commit }, user) {
        //     return AuthService.login(user).then(
        //         user => {
        //             commit('loginSuccess', user);
        //             return Promise.resolve(user);
        //         },
        //         error => {
        //             commit('loginFailure');
        //             return Promise.reject(error.response.data);
        //         }
        //     );
        // },
        // logout({ commit }) {
        //     AuthService.logout();
        //     commit('logout');
        // }
    },
    mutations: {
        loginSuccess(state, user) {
            localStorage.setItem('user', JSON.stringify(user));
            state.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            localStorage.removeItem('user');
            state.loggedIn = false;
            state.user = null;
        }
    }
};