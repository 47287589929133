<template>
  <div class="accordion">
    <AccordionItem
      v-for="accordionItem in data"
      :key="accordionItem.title"
      :accordion_data="accordionItem"
    />
  </div>
</template>

<script>
import AccordionItem from '@/components/Accordion/Accordion-item';

export default {
  data() {
    return {

    }
  },
  components: {
    AccordionItem,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables';

  .accordion {
    display: flex;
    flex-direction: column;

    &-enter-active, 
    &-leave-active {
      will-change: height, opacity;
      transition: height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), opacity .3s cubic-bezier(0.25, 0.8, 0.5, 1);
      overflow: hidden;
    }

    &-enter, 
    &-leave-to {
      height: 0 !important;
      opacity: 0;
    } 
  }
</style>