import Vue from 'vue'
import Vuex from 'vuex'

import {language} from './modules/language';
import {auth} from './modules/auth';
import {cards} from './modules/cards';
import {contact} from './modules/contact';


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading:false
    },
    getters: {
        loading: state => {
            return state.loading
        },
    },
    mutations: {
        setStatusLoading(state, status) {
            state.loading = status;
        },
    },
    modules: {
        language,
        auth,
        cards,
        contact
    }
})