<template>
  <div>
    <component v-bind:is="currentComponent" v-on:success="stepSuccess" :data="data"></component>
  </div>
</template>

<script>
import FromLogin from '@/components/auth/Login';
import FormConfirmCode from '@/components/auth/ConfirmCode';

export default {
  components: {
    FromLogin,
    FormConfirmCode
  },
  data() {
    return {
      data:{},
      currentComponent:'FromLogin'
    }
  },
  methods:{
    stepSuccess(data){
      this.data = Object.assign(this.data, data);
      this.currentComponent = data.nextComponent;
    }
  }
}
</script>