import axios from 'axios';
import authHeader from './auth.header';

const API_URL = '/api/';

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem('user');
        location.href='/';
    }
    return Promise.reject(error.response);
});

class ApiService {

    authSendCode(data) {
        return axios.post(API_URL + 'auth',data, { headers: authHeader() })
    }

    authConfirmCode(data) {
        return axios.post(API_URL + 'auth/confirm',data, { headers: authHeader() })
    }

    signupSendCode(data){
        return axios.post(API_URL + 'signup/code',data, { headers: authHeader() })
    }

    signupConfirmCode(data){
        return axios.post(API_URL + 'signup/confirm',data, { headers: authHeader() })
    }

    signupPersonalData(data){
        return axios.post(API_URL + 'signup',data, { headers: authHeader() })
    }

    forgotpasswordSendCode(data){
        return axios.post(API_URL + 'forgotpassword/code',data, { headers: authHeader() })
    }

    forgotpasswordConfirmCode(data){
        return axios.post(API_URL + 'forgotpassword/confirm',data, { headers: authHeader() })
    }

    forgotpassword(data){
        return axios.post(API_URL + 'forgotpassword',data, { headers: authHeader() })
    }

    contact(){
        return axios.get(API_URL + 'contact', { headers: authHeader() })
    }

    editContact(data){
        return axios.post(API_URL + 'contact',data, { headers: authHeader() })
    }

    changePassword(data){
        return axios.post(API_URL + 'contact/password',data, { headers: authHeader() })
    }

    changePhoneSendCode(data){
        return axios.post(API_URL + 'contact/phone/code',data, { headers: authHeader() })
    }

    addCard(data){
        return axios.post(API_URL + 'card',data, { headers: authHeader() })
    }

    cards(){
        return axios.get(API_URL + 'card', { headers: authHeader() })
    }

    transfer(data){
        return axios.post(API_URL + 'card/transfer', data,{ headers: authHeader() })
    }

    pay(data){
        return axios.post(API_URL + 'card/pay', data,{ headers: authHeader() })
    }

    fastPay(data){
        return axios.post(API_URL + 'card/fastpay', data,{ headers: authHeader() })
    }

    cardsSaved(){
        return axios.get(API_URL + 'card/saved', { headers: authHeader() })
    }

    deleteCreditCard(data){
        return axios.post(API_URL + 'card/delete', data,{ headers: authHeader() })
    }

    addCreditCard(data){
        return axios.post(API_URL + 'card/credit', data,{ headers: authHeader() })
    }

    deactivateAutoPay(data){
        return axios.post(API_URL + 'card/deactivateAutoPay', data,{ headers: authHeader() })
    }

    chequeByCardId(cardId){
        return axios.get(API_URL + 'card/cheque/'+cardId, { headers: authHeader() })
    }

    getPayCard(cardId){
        return axios.get(API_URL + 'card/pay/'+cardId, { headers: authHeader() })
    }
}

export default new ApiService();
