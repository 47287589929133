<template>
    <div 
			class="dropdown"
			:class="{ 'dropdown_active': isOpen,'dropdown_error':!state}"
		>
			<div class="dropdown__wrapper">
				<div class="dropdown__title" @click="isOpen = !isOpen">
					<div class="dropdown__title-text" :style="selected ? 'font-weight: bold;' : ''">
						{{selected || placeholder}}
					</div>
					<div class="dropdown__title-icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g opacity="0.56">
								<path d="M4.80273 6.66669L9.58398 11.3023L14.3652 6.66669L15.834 8.09381L9.58398 14.1667L3.33398 8.09381L4.80273 6.66669Z" fill="black"/>
							</g>
						</svg>
					</div>
				</div>
				<div class="note dropdown__error">
          {{errorText}}
        </div>
			</div>
			<div class="dropdown__options">
				<div class="dropdown__option"
					v-for="(option, index) in options"
					:key="index"
					@click="selectOption(option)"
				>
					{{ option.label }}
				</div>
			</div>
    </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    value:{
      type:[String,Number],
    },
    errorText:{
      type: String,
      default() {
        return ''
      }
    },
    placeholder:{
      type: String,
      default() {
        return ''
      }
    },
    requred:{
      type: Boolean,
      default() {
        return false
      }
    },
    state:{
      type: Boolean,
      default() {
        return true
      }
    },
  },
	data() {
		return {
			isOpen: false,
			selectedIndex: 0,
		}
	},
  computed:{
    selected(){

      for(let option of this.options){
        if(option.value==this.value){
          return option.label;
        }
      }

      return '';
    }
  },
	methods: {
		selectOption(option) {
			this.isOpen = false;
			this.$emit('input',option.value)
      this.$emit('change',option);
		},
	},
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables';
	
	.dropdown {
		position: relative;
		width: 100%;

		&_active {
			.dropdown {
				&__options {
					opacity: 1;
					visibility: visible;
					max-height: 325px;
				}

				&__title-icon {
					transform: rotate(180deg)
				}
			}
		}

		&_error {
			.dropdown {
				&__title {
					border-color: $color-error;
				}

				&__error {
					height: 17px;
				}
			}
		}

		&__error {
			color: $color-error;
			height: 0;
			transition: $transition;
			overflow: hidden;
		}

		&__title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid rgba($color-dark, 0.3);
			cursor: pointer;
			padding: 8px 0 6px;
			font-size: 16px;
			line-height: 16px;
			letter-spacing: -0.02em;
			color: rgba($color-dark, 0.56);

			&-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: $transition;
				margin-left: 5px;
			}
		}

		&__option {
			font-weight: bold;
			font-size: 16px;
			line-height: 16px;
			letter-spacing: -0.02em;
			color: rgba($color-dark, 0.9);
			cursor: pointer;
			padding: 10px;
			transition: $transition;

			&:not(:last-child) {
				margin-bottom: 8px;
			}

			&:hover {
				background-color: $color-green-light;
			}

			&s {
				position: absolute;
				top: 43px;
				left: 0;
				right: 0;
				z-index: 5;
				opacity: 0;
				visibility: hidden;
				padding: 8px;
				overflow: hidden;
				transition: $transition;
				background-color: $color-light;
				box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.07), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
			}
		}
	}
</style>