<template>
  <div class="header">
    <MobileMenu
        :isOpen="isOpenMenu"
    />
    <div class="container container_flex">
      <div class="header__left">
        <router-link
            class="header__logo"
            to="/"
        >
          <img alt="logo" src="@/assets/img/logo.svg">
        </router-link>
        <router-link
          class="header__programm"
          v-if="loggedIn"
          :to="{name:'About'}"
        >
          {{$t('header.about')}}
        </router-link>
      </div>
      <div class="header__right">
        <div
            class="header__btns"
            v-if="!loggedIn"
        >
          <router-link
              to="/"
              class="header__btn btn btn__secondary btn__secondary_outline-black"
          >
            {{$t('header.login')}}
          </router-link>
          <router-link
              to="/signup"
              class="btn btn__secondary btn__secondary_fill-black"
          >
            {{$t('header.signup')}}
          </router-link>
        </div>
        <div
            class="header__user"
            v-else
        >
          <img src="@/assets/img/user.svg" alt="" class="header__user-icon">
          <Dropdown
              value="/profile"
              :options="[
                {
                  label: $t('header.menu.main'),
                  value:'/'
                },
                {
                  label: $t('header.menu.profile'),
                  value:'/profile'
                },
                {
                  label: $t('header.menu.cards'),
                  value:'/cards'
                },
                {
                  label: $t('header.menu.logout'),
                  value:'/logout'
                }
              ]"
              @change="changePage"
          />
        </div>
        <div class="header__langs">
          <a class="header__lang" :class="{' header__lang_active':locale=='ru'}" @click="setLanguage('ru');$i18n.locale='ru';">RU</a>
          <div class="header__split"></div>
          <a class="header__lang" :class="{' header__lang_active':locale=='kz'}" @click="setLanguage('kz');$i18n.locale='kz'">KZ</a>
        </div>
        <div
            class="header__burger"
            :class="{ 'is-active': isOpenMenu }"
            @click="openMenu"
        >
          <button class="header__burger" type="button">
            <span class="header__burger-box">
              <span class="header__burger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MobileMenu from '@/components/Layouts/MobileMenu';

import { mapGetters,mapMutations } from "vuex";

export default {
  components: {
    MobileMenu
  },
  data() {
    return {
      isOpenMenu: false,
      // currentPath:null
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
      locale:"language/locale"
    })
  },
  methods: {
    ...mapMutations({
      setLanguage:'language/setLanguage'
    }),
    openMenu() {
      this.isOpenMenu = !this.isOpenMenu;
      document.querySelector('body').classList.toggle('body-lock');
    },
    changePage(data){
      this.$router.push({
        path:data.value
      })
    }
  },
  mounted() {
  },
  watch:{
    $route() {
      this.isOpenMenu=false;
      document.querySelector('body').classList.remove('body-lock');
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

  .header {
    position: relative;
    z-index: 10;
    padding: 20px 0;
    background-color: $color-light;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.07), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);

    @include breakpoint(991) {
      padding: 5px 0;
    }

    &__burger {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      padding: 2px;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      cursor: pointer;
      transition-property: opacity, filter;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      background-color: transparent;
      outline: none;
      border: none;
      background-color: $color-green-dark-apron;

      @include breakpoint(991) {
        display: flex;
      }

      &.is-active {
        .header__burger-inner,
        .header__burger-inner::before,
        .header__burger-inner::after {
          background-color: $color-light;
        }

        .header__burger {
          &-inner {
            transform: translate3d(0, -6px, 0) rotate(-45deg);
            transition-delay: 0.22s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::after {
              top: 0;
              opacity: 0;
              transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                opacity 0.1s 0.22s linear;
            }

            &::before {
              top: 0;
              transform: rotate(-90deg);
              transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
          }
        }
      }

      &-box {
        width: 20px;
        height: 14px;
        display: inline-block;
        position: relative;
      }

      &-inner {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $color-light;
        border-radius: 4px;
        position: absolute;
        top: auto;
        bottom: 0;
        transition-duration: 0.13s;
        transition-delay: 0.13s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before,
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: $color-light;
          border-radius: 4px;
          position: absolute;
        }

        &::before {
          top: -6px;
          transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &::after {
          top: -12px;
          transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1);
        }
      }
    }

    &__logo {
      width: 60px;
      height: 60px;

      @include breakpoint(991) {
        width: 42px;
        height: 42px;
      }
    }

    &__right,
    &__left,
    &__btns,
    &__langs {
      display: flex;
      align-items: center;
    }

    &__programm,
    &__langs,
    &__btns,
    &__user {
      @include breakpoint(991) {
        display: none !important;
      }
    }

    &__programm {
      font-weight: bold;
      font-size: 14px;
      line-height: 128%;
      margin-left: 24px;
      text-transform: uppercase;
    }

    &__btn {
      &:first-child {
        margin-right: 14px;
      }
    }

    &__split {
      width: 1px;
      height: 18px;
      background-color: $color-dark;
      margin: 0 5px;
    }

    &__lang {
      text-transform: uppercase;
      cursor: pointer;
      
      &s {
        margin-left: 50px;
      }

      &_active {
        border-bottom: 1px solid $color-dark;
      }
    }

    &__user {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 16px;
      }

      .dropdown {
        width: 140px;

        &__error {
          margin-top: 0;
        }
      }
    }

    .dropdown__title {
      border-bottom: none;
    }
  }
</style>
