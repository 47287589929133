<template>
  <div class="container container_small">
    <div class="profile" v-if="!loading">
      <div class="profile__wrapper">
        <div class="profile__content">
          <div class="profile__header">
            <div class="profile__title">
              {{$t('indexProfile.textHello')}}, {{ contact.FirstName }}!
            </div>
          </div>
          <div class="profile__level">
            <div class="profile__level-title">{{$t('indexProfile.textYourLevel')}}:</div>
            <div class="profile__level-value">
              <div 
                class="profile__level-value-color"
                :class="{'profile__level-value-color profile__level-value-color_green':contact.LevelId!='da67fab1-6722-ea11-80ce-001dd8b76eaf','profile__level-value-color profile__level-value-color_gold':contact.LevelId=='da67fab1-6722-ea11-80ce-001dd8b76eaf', }"
              >
              </div>
              <div class="profile__level-value-text">{{ contact.LevelName }}</div>
            </div>
          </div>
          <div class="profile__awards">
            <div class="profile__awards-item">
              <div class="profile__awards-item-text" v-html="$t('indexProfile.textStarsToFreeDrink')">
              </div>
              <div class="profile__awards-item-value">
                <div class="round-counter">{{ contact.StarsToFreeDrink }}</div>
                <div class="profile__awards-item-value-text">{{$t('indexProfile.textStarsToFreeDrinkStars')}}.</div>
              </div>
            </div>
            <div class="profile__awards-item">
              <div class="profile__awards-item-text" v-html="$t('indexProfile.textLevelnext',{'levelName':'золотого уровня'})">
              </div>
              <div class="profile__awards-item-value">
                <div class="round-counter">{{ contact.StarsToLevel }}</div>
                <div class="profile__awards-item-value-text">{{$t('indexProfile.textStarsToFreeDrinkStars')}}.</div>
              </div>
            </div>
          </div>
          <Aside v-if="!asideRight"/>
          <div class="profile__tabs-container">
            <div class="profile__tabs">
              <div 
                class="profile__tab"
                :class="{ 'profile__tab_active': activeTab == 0 }"
                @click="activeTab = 0"
              >
                {{$t('indexProfile.textGreenLevel')}}
              </div>
              <div 
                class="profile__tab"
                :class="{ 'profile__tab_active profile__tab_active-gold': activeTab == 1 }"
                @click="activeTab = 1"
              >
                {{$t('indexProfile.textGoldLevel')}}
              </div>
            </div>
            <div class="profile__tabs-content">
              <div class="profile__tabs-content-left">
                <div class="profile__tabs-content-item-counter">{{parseInt(contact.Rating)}}</div>
                <div class="profile__tabs-content-item-left">
                  <img src="@/assets/img/tabs-level-1.svg" alt="" class="profile__tabs-content-item-img">
                  <div class="profile__tabs-content-item-next">
                    <div class="profile__tabs-content-item-next-text">{{$t('indexProfile.textStarsToFreeDrink2')}}</div>
                    <div class="profile__tabs-content-item-next-value">
                      <div class="round-counter">{{ contact.StarsToFreeDrink }}</div>
                      <div class="profile__tabs-content-item-next-value-text">{{$t('indexProfile.textStarsToFreeDrinkStars')}}.</div>
                    </div>
                  </div>
                </div>
              </div>

              <div 
                class="profile__tabs-content-item"
                v-if="activeTab == 0"
              >
                <div class="profile__tabs-content-item-wrapper">
                  <div class="profile__tabs-content-item-accordion">
                    <Accordion
                      :data="accordions1[$i18n.locale]"
                    />

                    <p class="profile__note" v-if="$i18n.locale=='ru'">
                      Чтобы остаться на Зеленом уровне, Вам необходимо получить хотя бы одну звезду в течение 12 месяцев. Если Вы не собрали
                      ни одной звезды в течение 12 месяцев, звезды Зеленого уровня обнулятся. При этом счетчик звезд до награды* останется
                      неизменным.
                    </p>
                    <p class="profile__note" v-if="$i18n.locale=='kz'">
                      Жасыл деңгейде қалу үшін Сізге 12 айда кемінде бір Жұлдыз алу қажет. 12 ай ішінде бір де бір Жұлдыз жинамасаңыз, Жасыл деңгей Жұлдыздары нөлденеді. Мұндай кезде марапатқа* дейінгі Жұлдыз санағыш өзгеріссіз қалады.
                    </p>

                    <router-link
                        :to="{name:'About'}"
                        class="btn btn__secondary btn__secondary_outline-green accordion__item-btn"
                    >
                      {{$t('indexProfile.detailProgram')}}
                    </router-link>
                  </div>
                </div>
              </div>

              <div
                  class="profile__tabs-content-item"
                  v-if="activeTab == 1"
              >
                <div class="profile__tabs-content-item-wrapper">
                  <div class="profile__tabs-content-item-accordion">
                    <Accordion
                        :data="accordions2[$i18n.locale]"
                    />

                    <p class="profile__note" v-if="$i18n.locale=='ru'">
                      Золотой уровень можно получить, собрав 30 звезд в течение 12 месяцев. После перехода на Золотой уровень, он сохраняется в течение года. Чтобы продлить его на следующий год, необходимо собрать 30 звезд в течение года.
                    </p>

                    <p class="profile__note" v-if="$i18n.locale=='kz'">
                      Алтын деңгейді 12 ай ішінде 30 Жұлдыз жинау арқылы алуға болады. Алтын деңгейге өткеннен кейін, ол бір жыл бойы сақталады. Оны келесі жылға ұзарту үшін бір жыл ішінде 30 Жұлдыз жинау керек.
                    </p>

                    <router-link
                        :to="{name:'About'}"
                        class="btn btn__secondary btn__secondary_outline-green accordion__item-btn"
                    >
                      {{$t('indexProfile.detailProgram')}}
                    </router-link>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div class="profile__note">
            {{$t('indexProfile.note')}}
          </div>
        </div>
        <Aside v-if="asideRight"/>
      </div>  
    </div>
  </div>
</template>

<script>
import Aside from '@/components/profile/Aside';
import Accordion from '@/components/Accordion/Accordion';

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      activeTab: 0,
      accordions1:{
        'ru': [
            { title: 'Напиток за каждые 12 звезд',
              content: 'Напиток в подарок, кроме бутилированных, за каждые 12 звезд.',
            },
            { title: 'Новости и специальные предложения',
              content: 'Мы гарантируем, что Вы в числе первых услышите о каких-либо новых продуктах или специальных предложениях.'
            },
          ],
          'kz':[
            { title: 'Әр 12 Жұлдыз үшін сусын',
              content: 'Әр 12 Жұлдыз үшін сыйлыққа бөтелкедегі сусындардан басқа сусын беріледі.',
            },
            { title: 'Жаңалықтар мен арнайы ұсыныстар',
              content: 'Біз Сіздің қандай да бір жаңа өнім немесе арнайы ұсыныстар туралы бірінші болып еститініңізге кепілдік береміз.'
            },
          ]
      },
      accordions2: {
        'ru':[
            { title: 'Напиток за каждые 12 звезд',
              content: 'Напиток в подарок, кроме бутилированных, за каждые 12 звезд.',
            },
            { title: 'Карта в Золотом дизайне',
              content: 'Уникальная Карта, которую невозможно получить никаким другим образом. Только если Вы на Золотом уровне.'
            },
            { title: 'Награда в подарок на День Рождения',
              content: 'Мы не забудем про Ваш день рождения и поздравим Вас с этим прекрасным событием наградой в подарок.'
            },
            { title: 'Специальные предложения',
              content: 'Они будут работать только для тех, кто на Золотом Уровне.'
            },
            { title: 'Приглашение на специальные мероприятия',
              content: 'Иногда мы собираемся вместе. И будем приглашать Вас тоже!'
            },
            { title: 'Эксклюзивные условия промо акций',
              content: 'Ну, например, если это счастливый час, то для Вас это будет два часа.'
            }
          ],
        'kz':[
          { title: 'Әр 12 Жұлдыз үшін сусын',
            content: 'Әр 12 Жұлдыз үшін сыйлыққа бөтелкедегі сусындардан басқа сусын беріледі.',
          },
          { title: 'Алтын дизайнды карта',
            content: 'Басқа ешбір жолмен алынбайтын бірегей карта. Сіз Алтын деңгейінде болсаңыз ғана.'
          },
          { title: 'Туған күнге сыйлыққа марапат беріледі',
            content: 'Біз Сіздің туған күніңізді ұмытпаймыз және Сізді осы керемет оқиғамен сыйлық ретінде марапатпен құттықтаймыз.'
          },
          { title: 'Арнайы ұсыныстар',
            content: 'Олар тек Алтын деңгейдегі клиенттерге жасалады.'
          },
          { title: 'Арнайы іс-шараларға шақыру',
            content: 'Кейде біз бас қосамыз. Сізді де шақыратын боламыз!'
          },
          { title: 'Промо акциялардың эксклюзив шарттары',
            content: 'Мысалы, бұл бақытты сағат болса, онда Сіз үшін ол екі сағат болады.'
          }
        ],
      },
      asideRight: true,
    }
  },
  components: {
    Accordion,
    Aside
  },
  mounted() {
    window.addEventListener('resize', this.checkAside);
    this.checkAside();

    this.setStatusLoading(true);

    Promise.all([
      this.getContact(),
      this.getCards()
    ]).then(() => {
      this.setStatusLoading(false);
    });
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      contact: "contact/contact"
    })
  },
  methods:{
    ...mapMutations({
      setStatusLoading: "setStatusLoading",
    }),
    ...mapActions({
      getContact:'contact/getContact',
      getCards:'cards/getCards'
    }),
    checkAside() {
      window.innerWidth > 750 ? this.asideRight = true : this.asideRight = false;
    }
  }
}
</script>
