var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('MobileMenu',{attrs:{"isOpen":_vm.isOpenMenu}}),_c('div',{staticClass:"container container_flex"},[_c('div',{staticClass:"header__left"},[_c('router-link',{staticClass:"header__logo",attrs:{"to":"/"}},[_c('img',{attrs:{"alt":"logo","src":require("@/assets/img/logo.svg")}})]),(_vm.loggedIn)?_c('router-link',{staticClass:"header__programm",attrs:{"to":{name:'About'}}},[_vm._v(" "+_vm._s(_vm.$t('header.about'))+" ")]):_vm._e()],1),_c('div',{staticClass:"header__right"},[(!_vm.loggedIn)?_c('div',{staticClass:"header__btns"},[_c('router-link',{staticClass:"header__btn btn btn__secondary btn__secondary_outline-black",attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('header.login'))+" ")]),_c('router-link',{staticClass:"btn btn__secondary btn__secondary_fill-black",attrs:{"to":"/signup"}},[_vm._v(" "+_vm._s(_vm.$t('header.signup'))+" ")])],1):_c('div',{staticClass:"header__user"},[_c('img',{staticClass:"header__user-icon",attrs:{"src":require("@/assets/img/user.svg"),"alt":""}}),_c('Dropdown',{attrs:{"value":"/profile","options":[
              {
                label: _vm.$t('header.menu.main'),
                value:'/'
              },
              {
                label: _vm.$t('header.menu.profile'),
                value:'/profile'
              },
              {
                label: _vm.$t('header.menu.cards'),
                value:'/cards'
              },
              {
                label: _vm.$t('header.menu.logout'),
                value:'/logout'
              }
            ]},on:{"change":_vm.changePage}})],1),_c('div',{staticClass:"header__langs"},[_c('a',{staticClass:"header__lang",class:{' header__lang_active':_vm.locale=='ru'},on:{"click":function($event){_vm.setLanguage('ru');_vm.$i18n.locale='ru';}}},[_vm._v("RU")]),_c('div',{staticClass:"header__split"}),_c('a',{staticClass:"header__lang",class:{' header__lang_active':_vm.locale=='kz'},on:{"click":function($event){_vm.setLanguage('kz');_vm.$i18n.locale='kz'}}},[_vm._v("KZ")])]),_c('div',{staticClass:"header__burger",class:{ 'is-active': _vm.isOpenMenu },on:{"click":_vm.openMenu}},[_vm._m(0)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"header__burger",attrs:{"type":"button"}},[_c('span',{staticClass:"header__burger-box"},[_c('span',{staticClass:"header__burger-inner"})])])}]

export { render, staticRenderFns }