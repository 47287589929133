import Vue from 'vue'
import App from './App.vue'
import i18n from './locales';
import router from './router'
import store from './store'

Vue.config.productionTip = false

if (localStorage.language) {
  i18n.locale = localStorage.getItem('language');
} else {
  localStorage.setItem('language', i18n.locale);
}

//controls
import Button from "@/components/Controlls/Button";
import Input from "@/components/Controlls/Input";
import Checkbox from '@/components/Controlls/Checkbox'
import Radio from '@/components/Controlls/Radio'
import Dropdown from "@/components/Controlls/Dropdown";

Vue.component('Button',Button);
Vue.component('Input',Input);
Vue.component('Checkbox',Checkbox);
Vue.component('Radio',Radio);
Vue.component('Dropdown',Dropdown);

Vue.mixin({
  methods: {
    priceFormat: function (value) {
      return parseFloat(value)+' тнг.';
    }
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')