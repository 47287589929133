import i18n from '../../locales'

const state = {
  locale: localStorage.getItem('language') || i18n.locale,
};

const getters = {
  locale: state => {
    return state.locale;
  },
};
const actions = {
  setLanguage({commit}, language) {
    if (typeof language === 'string') {
      commit('setLanguage', language);
    }
  }
};
const mutations = {
  setLanguage(state, language) {
    localStorage.setItem('language', language)
    state.locale = language;
  }
};

export const language = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
