<template>
  <div class="container">
    <form class="form" @submit.prevent="submit" autocomplete="off">
      <div class="form__title">{{ $t('components.signup.title') }}</div>
      <p style="color: red;">{{errorText}}</p>
      <Input
          v-model="model.numberCard"
          :label="$t('components.signup.labelNumberCard')"
          :state="!validation.hasError('model.numberCard')"
          :error-text="validation.firstError('model.numberCard')"
          :requred="true"
      ></Input>

      <Input
          v-model="model.pin"
          :label="$t('components.signup.labelPin')"
          :state="!validation.hasError('model.pin')"
          :error-text="validation.firstError('model.pin')"
          :requred="true"
      ></Input>

      <Input
          v-model="model.email"
          :label="$t('components.signup.labelEmail')"
          :state="!validation.hasError('model.email')"
          :error-text="validation.firstError('model.email')"
          :requred="true"
      ></Input>

      <Input
          v-model="model.password"
          :label="$t('components.signup.labelPassword')"
          type="password"
          :state="!validation.hasError('model.password')"
          :error-text="validation.firstError('model.password')"
          :requred="true"
          note="Пароль должен иметь от 6 до 15 символов, включая одну заглавную букву, одну букву нижнего регистра и одну цифру."
      ></Input>

      <Input
          v-model="model.repPassword"
          :label="$t('components.signup.labelRepPassword')"
          type="password"
          :state="!validation.hasError('model.repPassword')"
          :error-text="validation.firstError('model.repPassword')"
          :requred="true"
      ></Input>

      <Input
          v-model="model.name"
          :label="$t('components.signup.labelName')"
          :state="!validation.hasError('model.name')"
          :error-text="validation.firstError('model.name')"
          :requred="true"
      ></Input>

      <Input
          v-model="model.secondName"
          :label="$t('components.signup.labelSecondName')"
          :state="!validation.hasError('model.secondName')"
          :error-text="validation.firstError('model.secondName')"
          :requred="true"
      ></Input>

      <Input
          v-model="model.birthday"
          :label="$t('components.signup.labelBirthday')"
          :state="!validation.hasError('model.birthday')"
          :error-text="validation.firstError('model.birthday')"
          :requred="true"
          :mask="'##-##'"
      ></Input>

      <div class="form__input-group" :class="{'form__input-group_error':validation.hasError('model.verifyToken')}">
        <div class="form__input-group-wrapper">
          <vue-recaptcha
              sitekey="6LfmZs4aAAAAAPbk4jnFuWahU3QBPTaTByO7_JTs"
              :loadRecaptchaScript="true"
              @verify="verifyEvent"
              @expired="expiredEvent"
              ref="recaptcha"
          ></vue-recaptcha>
        </div>
        <div class="note form__input-error">
          {{validation.firstError('model.verifyToken')}}
        </div>
      </div>

      <Checkbox
          v-model="model.checkbox1"
          :label="$t('components.signup.labelCheckbox1')"
      />
      <Checkbox
          v-model="model.checkbox2"
          :label="$t('components.signup.labelCheckbox2')"
          :requred="true"
          :state="!validation.hasError('model.checkbox2')"
      />
      <Checkbox
          v-model="model.checkbox3"
          :label="$t('components.signup.labelCheckbox3')"
          :requred="true"
          :state="!validation.hasError('model.checkbox3')"
      />
      <Checkbox
          v-model="model.checkbox4"
          :label="$t('components.signup.labelCheckbox4')"
          :requred="true"
          :state="!validation.hasError('model.checkbox4')"
      />

      <div class="note">
        {{$t('components.signup.note')}}
      </div>
      <div class="form__footer">
        <Button visual-style="fill" :loading="isLoading">{{$t('components.signup.btnCreate')}}</Button>
      </div>
    </form>
  </div>
</template>

<script>
import SimpleVueValidator from 'simple-vue-validator';
const Validator = SimpleVueValidator.Validator;
import VueRecaptcha from 'vue-recaptcha';

import ApiService from "@/services/api.service";

import {mapMutations} from "vuex";

export default {
  components: {
    VueRecaptcha
  },
  props: ['data'],
  data () {
    return {
      model: {
        numberCard:null,
        pin:null,
        email:'',
        password:'',
        repPassword:'',
        name:'',
        secondName:'',
        birthday:'',
        verifyToken:'',
        checkbox1:false,
        checkbox2:false,
        checkbox3:false,
        checkbox4:false,
        code: '',
      },
      submitted:false,
      errorText:'',
      isLoading:false
    }
  },
  methods:{
    ...mapMutations({
      loginSuccess: "auth/loginSuccess",
    }),
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
            if (success) {

              this.errorText='';
              this.isLoading=true;

              ApiService.signupPersonalData(Object.assign(this.model, {token:this.data.token})).then((resp)=>{

                this.loginSuccess(resp.data);

                window.dataLayer.push({'event':'Registration_step_2', 'eventCategory':'Registration_step_2', 'eventAction':'send'});

                this.$router.push({path:'/'});

              }).catch((err)=>{
                let data = err.data;
                this.errorText = data.errorText;
                this.$refs.recaptcha.reset();
                this.model.verifyToken = '';
                this.isLoading=false;
              });

            }
          }
      )
    },
    verifyEvent(token){
      this.model.verifyToken = token;
    },
    expiredEvent(){
      this.model.verifyToken = '';
    }
  },
  validators: {
    "model.numberCard": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).regex('^[0-9]*$', this.$t('errors.numberCard'));
    },
    "model.pin": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).regex('^[0-9]*$', this.$t('errors.pin'));
    },
    "model.email": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).email(this.$t('errors.email'));
    },
    "model.password": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).regex('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}',this.$t('errors.password'));
    },
    'model.repPassword, model.password': function (repeat, password) {
      if (this.submitted || this.validation.isTouched('repeat')) {
        return Validator.value(repeat).required(this.$t('errors.required')).match(password,this.$t('errors.repPassword'));
      }
    },
    "model.name": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    },
    "model.secondName": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    },
    "model.birthday": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).regex(/\d{2}-\d{2}/, this.$t('errors.birthday')).custom(() => {

          if(/\d{2}-\d{2}/.test(value)){

            let arDate = value.split('-');

            let date = new Date(2000, arDate[1]-1, arDate[0]);

              if(!(date.getFullYear() == 2000 && date.getMonth() == (arDate[1]-1) && date.getDate() == arDate[0])){
                return this.$t('errors.birthday');
              }
          }
      })
    },
    "model.verifyToken": function (value) {
      if(this.submitted)
        return Validator.value(value).required(this.$t('errors.required'));
    },
    "model.checkbox2": function (value) {
      if(this.submitted){

        if(!value)
          value='';

        return Validator.value(value).required(this.$t('errors.required'));
      }
    },
    "model.checkbox3": function (value) {
      if(this.submitted){

        if(!value)
          value='';

        return Validator.value(value).required(this.$t('errors.required'));
      }
    },
    "model.checkbox4": function (value) {
      if(this.submitted){

        if(!value)
          value='';

        return Validator.value(value).required(this.$t('errors.required'));
      }
    },
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>

<style lang="scss">
</style>
